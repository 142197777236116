import React, { Fragment, useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TextField, FormHelperText, MenuItem, TablePagination, Backdrop , CircularProgress } from '@material-ui/core';
import {Autocomplete} from '@material-ui/lab';
import {  getProfitdata, deleteProfitdata} from '../../actions/asyncActions';
import { makeStyles, useTheme } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
  circular: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));


const elementStyle ={
    border:'solid',
    borderRadius:'10px',
    position:'relative',
    left:'1vh',
    height:'6vh',
    width:'23vh',
    marginTop:'1vh',
    marginBottom:'1vh',
    textAlign: 'center'
  }


  
const ProfitabilityData = () => {
  const classes = useStyles();
    const user = JSON.parse(localStorage.getItem("m_users"));
    const group_id = user.group_id;
    const [dataprofit, setDataprofit] = React.useState([]);
    const [filteredBankStmt, setFilteredBankStmt] = useState([]);
    const [filteredinvoice, setFilteredInvoice] = useState([]);
    const [search, setSearch] = useState('');
     const [searchinv, setSearchinv] = useState('');
    var [isLoading, setIsLoading] = useState(false);
    const [invoicedatamodal, setinvoiceDataModal] = useState();
    const [invoiceDataFileTransactions, setinvoiceDataFileTransactions] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    useEffect( () => {
      getProfitdata()
      .then(resp => setDataprofit(resp.data))
    }, []);


    const updateSearch = (event) => {
    setSearch(event.target.value.substr(0,20));
   }


  const updateSearchinv = (event) => {
    setSearchinv(event.target.value.substr(0,20));
   }

 


     const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };
    const handleDeleteItem = (filename) => {
      const data ={
        filename: filename
      }
      deleteProfitdata(data)
      .then(resp => {
        getProfitdata()
        .then(resp => {
          setDataprofit(resp.data);
        });
      })
      .catch(err => {
        console.log("Error", err);
      })
    };

     useEffect(() => {
    setFilteredBankStmt(
     dataprofit.filter(n => {
      return n.invoice_no.toLowerCase().includes(search.toLowerCase());
     })
    )
  }, [search, dataprofit])


     // search for invoice

      useEffect(() => {
    setFilteredInvoice(
     invoiceDataFileTransactions.filter(n => {
      return n.invoice_no.toLowerCase().includes(searchinv.toLowerCase());
     })
    )
  }, [searchinv, invoiceDataFileTransactions])

    const handleViewTranscaction = (filename) => {
   setinvoiceDataModal(!invoicedatamodal)
   
   setinvoiceDataFileTransactions(
     filteredBankStmt.filter(f => f.prod_prof_filename == filename)
    )
  }

 const inv = filteredBankStmt.reduce((unique, s) => (unique.includes(s.prod_prof_filename) ? unique : [...unique, s.prod_prof_filename]),[],) ;

  
        return (
            <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-block row">
                                <div className="col-sm-12 col-lg-12 col-xl-12">
                                 <input type="text" placeholder="Search Items" style={elementStyle} value={search}
                                       onChange={e => setSearch(e.target.value)} />
                                    <div className="table-responsive-xl">
                                        <table className="table table-bordered table-hover">
                                            <thead className="table-primary">
                                                <tr className="text-center">
                                                   <th scope="col">Year</th>
                                                    
                                                    <th scope="col">Month</th>
                                                    <th scope="col">FileName</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                             
                                            <tbody>
                                                {inv.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((n, i)=> {
                                                  const sa = filteredBankStmt.find(z => z.prod_prof_filename === n);
                                                  const filename = sa && sa.prod_prof_filename;
                                                  const Month = sa && sa.month;
                                                  const year = sa && sa.year
                                                  return (
                                                    <tr className="table-sm">
                                                     <td>{year}</td>
                                                     <td>{Month}</td>
                                                     <td>{filename}</td>
                                                     <td>
                                                      <i className="fa fa-eye" onClick={() => handleViewTranscaction(filename)} style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e' }}
                                                      ></i>
                                                      <i className="fa fa-trash" onClick={() => handleDeleteItem(filename)} style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e' }}
                                                      ></i>
                                                     </td>
              
                                                    </tr>
                                                  );
                                                })
                                              }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                  <div className="card">
                                    <div className="card-block row">
                                      <div className="col-sm-6 col-lg-6 col-xl-6 m-t-15">
                                      </div>
                                      <div className="col-sm-6 col-lg-6 col-xl-6">
                                        <TablePagination
                                          rowsPerPageOptions={[5, 10, 25]}
                                          component="div"
                                          count={inv.length}
                                          rowsPerPage={rowsPerPage}
                                          page={page}
                                          onChangePage={handleChangePage}
                                          onChangeRowsPerPage={handleChangeRowsPerPage}
                                         />
                                      </div>
                                    </div>
                                  </div>
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <Modal className="modal-dialog modal-xl" isOpen={invoicedatamodal} toggle={handleViewTranscaction}>
                <ModalHeader toggle={handleViewTranscaction}>Transactions List
                <div style={{textAlign: 'right'}}>
                <input type="text" placeholder="Search Items" style={elementStyle} value={searchinv}
                                       onChange={e => setSearchinv(e.target.value)} />
                </div>
                </ModalHeader>

                <ModalBody>
                <div className="table-responsive">
                  <table className="table table-bordered table-hover">
                                            <thead className="table-primary">
                                                <tr className="text-center">
                                                    <th scope="col">Invoice_no</th>
                                                    <th scope="col">Invoice_type</th>
                                                    <th scope="col">Sale_amount</th>
                                                    <th scope="col">Cost</th>
                                                    <th scope="col">Profit</th>
                                                    <th scope="col">Profit_pc</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                  {filteredinvoice.map((n, i)=> {
                                                  return (
                                                    <tr className="table-sm">
                                                     <td>{n.invoice_no}</td>
                                                     <td>{n.invoice_type}</td>
                                                     <td>{parseFloat(n.sale_amount).toFixed(2).toLocaleString('en', {minimumFractionDigits: 2})}</td>
                                                     <td>{parseFloat(n.cost).toFixed(2).toLocaleString('en', {minimumFractionDigits: 2})}</td>
                                                     <td>{parseFloat(n.profit).toFixed(2).toLocaleString('en', {minimumFractionDigits: 2})}</td>
                                                     <td>{parseFloat(n.profit_pc).toFixed(2).toLocaleString('en', {minimumFractionDigits: 2})}</td> 
                                                    </tr>
                                                  );
                                                })
                                              }
                                            </tbody>
                                        </table>
                                        </div>
                </ModalBody>
                <ModalFooter>
                    
                    <Button color="secondary" onClick={handleViewTranscaction}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </Fragment>
        );
}

export default ProfitabilityData;