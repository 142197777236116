import React, { Fragment, useState, useEffect } from 'react';
//import Breadcrumb from '../../components/common/breadcrumb';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {TextField, Backdrop , CircularProgress} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { ToastContainer, toast } from 'react-toastify';
import {Autocomplete} from '@material-ui/lab';
import { getStockdata ,uploadpl, Stockscript } from '../../actions/asyncActions';

const useStyles = makeStyles(theme => ({
  circular: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const InvoiceProcessing = () => {
 
 const classes = useStyles();
 const theme = useTheme();
 
 const [typeval, setTypeVal] = useState(null);
 var [isLoading, setIsLoading] = useState(false);
 const [boms, setBoms] = useState([]);
 const [bom_items, setBOMItems] = useState([]);
 const [viewmodal, setViewModal] = useState();

 const [vendorForm, setVendors] = useState([]);
 const [vendor_val, setVendorVal] = useState(null);
 
 const [stockdata, setStockdata] = useState([]);
 const [porder_val, setPorderVal] = useState(null);

 const [invoicefile, setInvoiceFile] = React.useState([]);

  useEffect( () => {
      getStockdata()
      .then(resp => setStockdata(resp.data))
    }, []);




  const viewBOM = n => {
     setViewModal(!viewmodal)
  }

  const handleUploadInvoice = () => {
   setInvoiceFile(document.getElementById('fileinvoice').files[0]);
 }

 const handleSubmit = async (event) => {

    // const aa = salesprofitdata.filter(x => x.month == vendor_val.label);
    //  if(aa) {
    //   alert ("Month already in Database")
    //  }
    //  else {
      const formData = new FormData()
      formData.append( 'file', invoicefile )
      const inv = await uploadpl(formData);
      const aaa = inv.data.filename;
      const invpath = "/Data/" + aaa;
      
      const data = {
        filename: aaa,
        pl_filename: invpath,
        year: porder_val.value,
        quarter: vendor_val.label
      } 

      setIsLoading(true);
      if (data) {
         Stockscript(data)
            .then(resp => {  
            })
        setTimeout(() => {  
           toast.success("Invoice uploaded successfully !!!", {position: toast.POSITION.TOP_CENTER, autoClose: 5000})
          setIsLoading(false)
        }, 15000);
      }
      
  }

 
  const months = [
  { value: 'Q1', label: 'Q1' },
  { value: 'Q2', label: 'Q2' },
  { value: 'Q3', label: 'Q3' },
  { value: 'Q4', label: 'Q4' }
  ]

  const years = [
  { value: '2019', label: '2019' },
  { value: '2020', label: '2020' },
  { value: '2021', label: '2021' }
  
  ]

	return (
        <Fragment>
         
         <div className="container-fluid">
         {isLoading ? (
          <Backdrop className={classes.circular} open={isLoading}>
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : (
            <div className="row">
             <div className="col-sm-12 col-xl-6">
                <div className="card card-absolute">
                    <div className="card-header bg-secondary">
                        <h5>Upload Invoice</h5>
                    </div>
                    <div className="card-body">
                    
		             <div className="col-md-12">
		              <Autocomplete 
                    freeSolo
                    options={years}
                    getOptionLabel={option => option.label}
                    value={porder_val}                      
                    onChange={(event, newValue) => {
                      setPorderVal(newValue);
                    }}            
                    renderInput={params => (
                      <TextField {...params} label="Year" margin="normal" variant="outlined" helperText="Please select Year" fullWidth />
                    )}
                  />
		             </div>
                  <div className="col-md-12">
                      <Autocomplete
                        freeSolo
                        options={months}
                        getOptionLabel={option => option.label}
                        value={vendor_val}                      
                        onChange={(event, newValue) => {
                          setVendorVal(newValue);
                        }}            
                        renderInput={params => (
                          <TextField {...params} label="Quarter" margin="normal" variant="outlined" helperText="Please select Quarter" fullWidth />
                        )}
                      />
                 </div>
		             <div className="col-md-12">
		              <label className="col-form-label text-body" htmlFor="message-text">Attach Invoice:</label>
                      <TextField id="fileinvoice" type="file" margin="dense" variant="outlined" onChange={handleUploadInvoice} fullWidth/>
                     </div>
                    </div>
                    <div className="card-footer text-center">
                      <button className="btn btn-pill btn-secondary-gradien btn-lg" type="button" onClick={handleSubmit}>Submit</button>
                    </div>
                </div>
             </div>
            </div>
            )}
          </div> 
        
        {/* < !-- Using Modal for Viewing BOM Items --> */}
            <Modal isOpen={viewmodal} toggle={viewBOM} className="modal-dialog modal-xl">
                <ModalHeader toggle={viewBOM}>View BOM Items</ModalHeader>   
                <ModalBody>
                   <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-block row">
                                <div className="col-sm-12 col-lg-12 col-xl-12">
                                    <div className="table-responsive">
                                        <table className="table table-bordered table-hover">
                                            <thead className="table-primary">
                                                <tr>
                                                    <th scope="col">Sl.No</th>
                                                    <th scope="col">Document No</th>
                                                    <th scope="col">Item Class</th>
                                                    <th scope="col">Part No</th>
                                                    <th scope="col">Part Name</th>
                                                    <th scope="col">Function</th>
                                                    <th scope="col">Item Type</th>
                                                    <th scope="col">Material</th>
                                                    <th scope="col">Qty</th>
                                                    <th scope="col">Finish Size</th>
                                                    <th scope="col">Remarks</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {bom_items.map((n)=> {
                                                  return (
                                                    <tr>
                                                     <td>{n.sl_no}</td>
                                                     <td>{n.document_no}</td>
                                                     <td>{n.item_class}</td>
                                                     <td>{n.part_num}</td>
                                                     <td>{n.part_name}</td>
                                                     <td>{n.function}</td>
                                                     <td>{n.item_type}</td>
                                                     <td>{n.material}</td>
                                                     <td>{n.qty}</td>
                                                     <td>{n.finish_size}</td>
                                                     <td>{n.remarks}</td>
                                                     <td>
                                                      <button className="btn btn-info btn-xs" type="button">
                                                       <i className="icofont icofont-edit"></i>
                                                      </button>
                                                      <button className="btn btn-danger btn-xs" type="button">
                                                       <i className="fa fa-trash-o"></i>
                                                      </button>
                                                     </td>
                                                    </tr>
                                                  );
                                                })
                                              }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="info" onClick={viewBOM}>Add BOM Items</Button>
                    <Button color="secondary" onClick={viewBOM}>Close</Button>
                </ModalFooter>
            </Modal>
          </Fragment>
    );
}
export default InvoiceProcessing;