import React, { Fragment } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { Home, Settings, MoreHorizontal  } from 'react-feather';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import SalesmanInvoice from './salesmaninvoice';
import Salesmandata from './salesmandata';


 

const salesTab = () => {
        return (
            <Fragment>
                <Breadcrumb title="Salesmandata" parent="Salesmandata" />
                <div className="container-fluid">
                    <div className="row theme-tab">
                        <Tabs className="col-sm-12">
                            <TabList className="tabs tab-title">
                                <Tab className="current">
                                    <Home />Salesman Upload
                                </Tab>
                                <Tab>
                                    <Settings />Salesmandata
                                </Tab>
                            </TabList>
                            <div className="tab-content-cls">
                                <TabPanel>
                                    <SalesmanInvoice />
                                </TabPanel>
                                <TabPanel>
                                    <Salesmandata />
                                </TabPanel>
                                
                            </div>
                        </Tabs>
                    </div>
                </div>
            </Fragment>
        );
}

 

export default salesTab;