import React, { Fragment } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { Home, Settings, MoreHorizontal  } from 'react-feather';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import StockInvoice from './stockinvoice';
import Stockdata from './stockdata';


const stockTab = () => {
        return (
            <Fragment>
                <Breadcrumb title="Stockdata" parent="Stockdata" />
                <div className="container-fluid">
                    <div className="row theme-tab">
                        <Tabs className="col-sm-12">
                            <TabList className="tabs tab-title">
                                <Tab className="current">
                                    <Home />Stock Upload
                                </Tab>
                                <Tab>
                                    <Settings />Stockdata
                                </Tab>
                            </TabList>
                            <div className="tab-content-cls">
                                <TabPanel>
                                    <StockInvoice />
                                </TabPanel>
                                <TabPanel>
                                    <Stockdata />
                                </TabPanel>
                                
                            </div>
                        </Tabs>
                    </div>
                </div>
            </Fragment>
        );
}

 

export default stockTab;