import React, { Fragment, useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {TextField, Backdrop , CircularProgress} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { ToastContainer, toast } from 'react-toastify';
import {Autocomplete} from '@material-ui/lab';
import { getStockdata ,uploadpl, Predictscript } from '../../actions/asyncActions';

const useStyles = makeStyles(theme => ({
  circular: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const InvoiceProcessing = () => {
 
 const classes = useStyles();
 const theme = useTheme();
 
 const [typeval, setTypeVal] = useState(null);
 var [isLoading, setIsLoading] = useState(false);
 const [boms, setBoms] = useState([]);
 const [bom_items, setBOMItems] = useState([]);
 const [viewmodal, setViewModal] = useState();

 const [vendorForm, setVendors] = useState([]);
 
 
 const [stockdata, setStockdata] = useState([]);
 const [cli_lvl, setCli_lvl] = useState(null);
 const [cat_lvl, setCat_lvl] = useState(null);
 const [mer_lvl, setMer_lvl] = useState(null);

 const [invoicefile, setInvoiceFile] = React.useState([]);

  useEffect( () => {
      getStockdata()
      .then(resp => setStockdata(resp.data))
    }, []);




  const viewBOM = n => {
     setViewModal(!viewmodal)
  }

  const handleUploadInvoice = () => {
   setInvoiceFile(document.getElementById('fileinvoice').files[0]);
 }

 const handleSubmit = async (event) => {
      const data = {
        client_level: cli_lvl.level,
        cat_level: cat_lvl.level,
        merc_level: mer_lvl.level
      } 

      console.log("data", data);

      setIsLoading(true);
      if (data) {
         Predictscript(data)
            .then(resp => {  
            })
        setTimeout(() => {  
           toast.success("Invoice uploaded successfully !!!", {position: toast.POSITION.TOP_CENTER, autoClose: 5000})
          setIsLoading(false)
        }, 15000);
      }
      
  }

 
  const months = [
  { value: 'Q1', label: 'Q1' },
  { value: 'Q2', label: 'Q2' },
  { value: 'Q3', label: 'Q3' },
  { value: 'Q4', label: 'Q4' }
  ]

  const years = [
  { value: '2019', label: '2019' },
  { value: '2020', label: '2020' },
  { value: '2021', label: '2021' }
  
  ]

  const client = [
{name:"Technica Forensics Ltd", level:"Client_Level_1"},
{name:"Hamad Medical Corporation", level:" Client_Level_1"},
{name:"Qatar Gas Operating Co.Ltd", level:"  Client_Level_1"},
{name:"Oryx Gtl", level:"  Client_Level_1"},
{name:"Gulf Drilling International", level:" Client_Level_1"},
{name:"Qatar Chemical Company Ltd", level:"  Client_Level_2"},
{name:"Qatar Engineering & Construction Company", level:"Client_Level_2"},
{name:"Ooredoo Qatar", level:" Client_Level_2"},
{name:"Qatar Aluminium Limited Company", level:" Client_Level_2"},
{name:"Primary Health Care Cooperation", level:" Client_Level_2"},
{name:"Ras Lafan Olefins Company Ltd", level:"  Client_Level_2"},
{name:"Qatar Petroleum", level:" Client_Level_2"},
{name:"Gulf Warehousing Company Qpsc", level:" Client_Level_2"},
{name:"Descon Engineering Qatar L.L.C", level:"  Client_Level_2"},
{name:"Market Hub Innovational Llc", level:" Client_Level_2"},
{name:"Cash", level:"  Client_Level_2"},
{name:"General Authority Of Customs", level:"  Client_Level_2"},
{name:"Mc Dermott Middle East Inc", level:"  Client_Level_2"},
{name:"North Oil Company", level:" Client_Level_2"},
{name:"Madina Group Wll", level:"  Client_Level_2"},
{name:"Middle East Fujji Llc (Qatar)", level:" Client_Level_2"},
{name:"Nakilat-Keppel Offshore&Marineltd", level:" Client_Level_2"},
{name:"Venture Gulf Engineering Wll", level:"  Client_Level_2"},
{name:"Sidra Medicine", level:"  Client_Level_2"},
{name:"Saipem S.P.A", level:"  Client_Level_2"},
{name:"Al Ahed Trading & Contracting Co. Ltd", level:" Client_Level_2"},
{name:"Insulation Engineering Company", level:"  Client_Level_2"},
{name:"Schlumberger Overseas Sa", level:"  Client_Level_2"},
{name:"Suez International", level:"  Client_Level_2"},
{name:"Qatar Gas Transport Co. (Nakilat)", level:" Client_Level_2"},
{name:"Dolphin Energy Ltd", level:" Client_Level_2"},
{name:"Energy Technical Services", level:" Client_Level_2"},
{name:"Al Balagh Trading & Contracting Wll", level:" Client_Level_2"},
{name:"Mol Lng Transport (Europe) Ltd", level:"  Client_Level_2"},
{name:"Qatar Petrochemical Company", level:" Client_Level_2"},
{name:"Black Cat Engineering & Construction", level:"  Client_Level_2"},
{name:"Hatcom For Trading & Contracting", level:"  Client_Level_2"},
{name:"Manchester Safety Equipments", level:"  Client_Level_2"},
{name:"Cape East Ltd", level:" Client_Level_2"},
{name:"Safe Tracs International Trading", level:"  Client_Level_2"},
{name:"Seashore Trading Company", level:"  Client_Level_2"},
{name:"Supreme Supply & Services", level:" Client_Level_2"},
{name:"Emerald Driller Company Qatar", level:" Client_Level_2"},
{name:"Applus Velosi", level:" Client_Level_2"},
{name:"Master Mind Trading Est", level:"Client_Level_3"},
{name:"Janeth Lopez", level:"  Client_Level_3"},
{name:"Chubb International Gulf Trading Co-Chubb Fire", level:"  Client_Level_3"},
{name:"Anabeeb Services Co.Wll", level:" Client_Level_3"},
{name:"Better Group", level:"  Client_Level_3"},
{name:"Total E&P Qatar", level:" Client_Level_3"},
{name:"Milaha", level:"  Client_Level_3"},
{name:"Qatar Steel Company", level:" Client_Level_3"},
{name:"Integrated Trading Co. W.L.L", level:"  Client_Level_3"},
{name:"Tyco Qatar Fire & Safety", level:"  Client_Level_3"},
{name:"Qatar Fuel Additives Co, Ltd", level:" Client_Level_3"},
{name:"Century Trading & Contracting Wll", level:" Client_Level_3"},
{name:"Kaefer L.L.C", level:"  Client_Level_3"},
{name:"Sievert International Inspection Co Wll", level:" Client_Level_3"},
{name:"Gulf Turboservices", level:"  Client_Level_3"},
{name:"Sunman Trading And Contracting Wll", level:"  Client_Level_3"},
{name:"Qatar Plant Hire Co.Wll", level:" Client_Level_3"},
{name:"Worleyparsons Qatar Wll", level:" Client_Level_3"},
{name:"Doha Petroleum Cons. Co. Ltd (Dopet)", level:"  Client_Level_3"},
{name:"Amec Black Cat", level:"  Client_Level_3"},
{name:"Qatar Fertiliser Company (S.A.Q)", level:"  Client_Level_3"},
{name:"Craig International Trading In Oil Field Equipment", level:"  Client_Level_3"},
{name:"Bureau Veritas", level:"  Client_Level_3"},
{name:"Galfar Al Misnad", level:"  Client_Level_3"},
{name:"Sealand Marine Services", level:" Client_Level_3"},
{name:"Mc Dermott Eastern Hemisphere", level:" Client_Level_3"},
{name:"Aiwa Trading Wll", level:"  Client_Level_3"},
{name:"Jalal", level:" Client_Level_3"},
{name:"Concord International Import & Export Co. Wll", level:" Client_Level_3"},
{name:"Halliburton Worldwide Limited", level:" Client_Level_3"},
{name:"Ashgal- Public Works Authority", level:"  Client_Level_4"},
{name:"Splendid Project Company Wll", level:"  Client_Level_4"},
{name:"Contract Resources Oilfield Services Wll", level:"  Client_Level_4"},
{name:"Bangkok Centre Wll", level:"  Client_Level_4"},
{name:"Buchen Industrial Service Middle East", level:" Client_Level_4"},
{name:"Danem Engineering Works Wll Client_Level_4"},
{name:"Quick Touch Trading&Technical Co.", level:" Client_Level_4"},
{name:"Nakilat Shipping (Qatar) Limited", level:"  Client_Level_4"},
{name:"Prosafe Systems", level:" Client_Level_4"},
{name:"Hbk Contracting Co. W.L.L", level:" Client_Level_4"},
{name:"Rak Energy Services And Supplues Wll", level:"  Client_Level_4"},
{name:"Hot -Hed Middle East W.L.L", level:"  Client_Level_4"},
{name:"Emco Facilities Management", level:"  Client_Level_4"},
{name:"Staref General Maintenance Services", level:" Client_Level_4"},
{name:"Readymix Qatar Limited Llc", level:"  Client_Level_4"},
{name:"Al Jasmi Trading Est", level:"  Client_Level_4"},
{name:"Mingshi", level:" Client_Level_4"},
{name:"Silver Falcon Contracting & Trading Wll", level:" Client_Level_4"},
{name:"Al Shaheen Ge Service Company", level:" Client_Level_4"},
{name:"Future Engineering Company", level:"  Client_Level_4"},
{name:"Ardor Trading & Contracting Wll", level:" Client_Level_4"},
{name:"Raytheon Company", level:"  Client_Level_4"},
{name:"Al Muftah Contracting Com. W.L.L", level:"  Client_Level_4"},
{name:"Sts Qatar - Special Technical Services Llc", level:"  Client_Level_4"},
{name:"Qatar Tec", level:" Client_Level_4"},
{name:"Hydromech", level:" Client_Level_4"},
{name:"Hbk-Crcc Joint Venture", level:"  Client_Level_4"},
{name:"G4s", level:" Client_Level_4"},
{name:"Merimac", level:" Client_Level_4"},
{name:"Japan Drilling (Netherlands) B", level:"  Client_Level_4"},
{name:"Sahara Oilfield Supplies & Trading Wll", level:"  Client_Level_4"},
{name:"A-Z International Co. Wll", level:" Client_Level_4"},
{name:"Brightway International", level:" Client_Level_4"},
{name:"Kompass Trading", level:" Client_Level_4"},
{name:"Kentech Qatar Technical Services Wll", level:"  Client_Level_4"},
{name:"Dci", level:" Client_Level_4"},
{name:"Arabian Oasis", level:" Client_Level_4"},
{name:"Spectrum Industries W.L.L", level:" Client_Level_4"},
{name:"Gec Contracting Services&Trading", level:"  Client_Level_4"},
{name:"Boecker Public Safety, Llc", level:"  Client_Level_4"},
{name:"Ming Shi", level:"  Client_Level_4"},
{name:"Perfect Hardware Trading Company", level:"  Client_Level_4"},
{name:"Sarplast Qatar Wll", level:"  Client_Level_4"},
{name:"Ucc Holding", level:" Client_Level_4"},
{name:"Harsco", level:"  Client_Level_4"},
{name:"Nobles Trading  Services", level:"  Client_Level_4"},
{name:"Petrotechnical Supplies & Services Wll", level:"  Client_Level_4"},
{name:"Siemens Wll", level:" Client_Level_4"},
{name:"Qatar Fuel (Woqod)", level:"  Client_Level_4"},
{name:"Energy And Industrial Solutions Wll", level:" Client_Level_4"},
{name:"Al Masood Oil Industry Suply & Services Co. Wll", level:" Client_Level_4"},
{name:"Facilities Management And Maintenance Company", level:" Client_Level_4"},
{name:"Unitec Trading", level:"  Client_Level_4"},
{name:"Midmac Contracting Company Wll", level:"  Client_Level_4"},
{name:"Qatar Al-Attiyah International Group", level:"  Client_Level_4"},
{name:"Silver Stone Trading&Contracting Wll", level:"  Client_Level_4"},
{name:"Harbour Trad.& Tec Service Wll", level:"  Client_Level_4"},
{name:"M-I Qatar Wll", level:" Client_Level_4"},
{name:"Offshore Industrial Suppliers Wll", level:" Client_Level_5"},
{name:"Buyer Solutions", level:" Client_Level_5"},
{name:"Bin Arbaid Trans & Equip Wll", level:"  Client_Level_5"},
{name:"Al Muftah Fibreglas Products Wll", level:"  Client_Level_5"},
{name:"Wrightman-Trading", level:" Client_Level_5"},
{name:"Assiyana Gulf Serv Wll", level:"  Client_Level_5"},
{name:"Zonal Trading & Contracting Wll", level:" Client_Level_5"},
{name:"Seef", level:"  Client_Level_5"},
{name:"Total Safety", level:"  Client_Level_5"},
{name:"Nasser Bin Khalid Al Thani & Sons Holding Co.", level:" Client_Level_5"},
{name:"Bridgeway Technical Services Wll.", level:" Client_Level_5"},
{name:"Megarme Qatar Llc", level:" Client_Level_5"},
{name:"Qpmc", level:"  Client_Level_5"},
{name:"Marketing Hub", level:" Client_Level_5"},
{name:"Market Hub Innovations", level:"  Client_Level_5"},
{name:"Sun Tech Wll", level:"  Client_Level_5"},
{name:"Minar Enterprises Wll", level:" Client_Level_5"},
{name:"Al Jabor Cement Industries Co", level:" Client_Level_5"},
{name:"Solarline Trading", level:" Client_Level_5"},
{name:"Abb Llc", level:" Client_Level_5"},
{name:"Delta Corporation", level:" Client_Level_5"},
{name:"Proactive Technical Services Wll", level:"  Client_Level_5"},
{name:"Qatar Kentz", level:" Client_Level_5"},
{name:"Jaidah Motors And Trading Co.", level:" Client_Level_5"},
{name:"Qdvc (Qsc)", level:"  Client_Level_5"},
{name:"Quality Based Structures Wll", level:"  Client_Level_5"},
{name:"Reliable Trading & Hospital Services Co,Wll", level:" Client_Level_5"},
{name:"Epic International Trading & Contracting", level:"  Client_Level_5"},
{name:"Ultimate Trading & Cont. Co", level:" Client_Level_5"},
{name:"Aarvee International Wll", level:"  Client_Level_5"},
{name:"Growth Map", level:"  Client_Level_5"},
{name:"Soft Trading", level:"  Client_Level_5"},
{name:"Loyal Trading", level:" Client_Level_5"},
{name:"Abdullah Abdul Ghani & Bros. Co. Wll", level:"  Client_Level_6"},
{name:"Umm Al Houl Power", level:" Client_Level_6"},
{name:"Hamad Medical Corporation C/O Dr. Shuhaib Afzal", level:" Client_Level_6"},
{name:"Accolade Trading Wll", level:"  Client_Level_6"},
{name:"Torpido", level:" Client_Level_6"},
{name:"Ras Trading & Services Est (Rastec)", level:" Client_Level_6"},
{name:"Fakhruddin Kaderbhai Trading Co Wll", level:" Client_Level_6"},
{name:"Nimr", level:"  Client_Level_6"},
{name:"Al Haya", level:" Client_Level_6"},
{name:"Qatar Primary Materials Co.", level:" Client_Level_6"},
{name:"K3international", level:" Client_Level_6"},
{name:"Dr.Abdallah Mohamed M E", level:" Client_Level_6"},
{name:"Ws Atkins & Partners Overseas", level:" Client_Level_6"},
{name:"Snc Lavalin Profac Gulf Management Llc", level:"  Client_Level_6"},
{name:"K3 International", level:"  Client_Level_6"},
{name:"Sky Creative Trading & Contracting", level:"  Client_Level_6"},
{name:"Black Cat Consulting & Engineering Services", level:" Client_Level_6"},
{name:"Ma Perpetual Cesar San Pedro", level:"  Client_Level_6"},
{name:"Makyna", level:"  Client_Level_6"},
{name:"Bright Technical", level:"  Client_Level_6"},
{name:"Al Jaber Transport And General Contracting", level:"  Client_Level_6"},
{name:"M/S. Power Waste Management", level:" Client_Level_6"},
{name:"Cherri Ddc", level:"  Client_Level_6"},
{name:"Qd-O&M", level:"  Client_Level_6"},
{name:"Aktor Qatar W.L.L", level:" Client_Level_6"},
{name:"Dunia Trading Wll", level:" Client_Level_6"},
{name:"Cheska", level:"  Client_Level_6"},
{name:"Reetchee", level:"  Client_Level_6"},
{name:"Apollo Enterprise", level:" Client_Level_6"},
{name:"Hilti", level:" Client_Level_6"},
{name:"Hbk Engineering Services Wll", level:"  Client_Level_6"},
{name:"Techno Trust Trading Wll", level:"  Client_Level_6"},
{name:"Dico Tech Qatar Wll", level:" Client_Level_6"},
{name:"Domo Qatar", level:"  Client_Level_6"},
{name:"Cdc", level:" Client_Level_6"},
{name:"Tech Base", level:" Client_Level_6"},
{name:"Trading And Agency Services Ltd (Trags)", level:" Client_Level_6"},
{name:"Vijay Arabia Fire & Security", level:"  Client_Level_6"},
{name:"Alnahada", level:"  Client_Level_6"},
{name:"Axiom International W.L.L", level:" Client_Level_6"},
{name:"Mohammed", level:"  Client_Level_6"},
{name:"Seasite International Trading & Contracting", level:" Client_Level_6"},
{name:"Doha-Oasis", level:"  Client_Level_6"},
{name:"Al Madina Trading Services & Contracting Co,", level:"  Client_Level_6"},
{name:"Gulf Contracting Co. W.L.L", level:"  Client_Level_6"},
{name:"Furuzai Intl. Enterprises Wll", level:" Client_Level_6"},
{name:"Pipeline Suppliesd Services Co.W.L.L", level:"  Client_Level_6"},
{name:"Nimr Oil Well Maintenance Company", level:" Client_Level_6"},
{name:"Cosmoz", level:"  Client_Level_6"},
{name:"Mms Trading", level:" Client_Level_6"},
{name:"Smart Empower International Wll", level:" Client_Level_6"},
{name:"Q-Reliance International Wll", level:"  Client_Level_6"},
{name:"Saraya Corniche Hotel", level:" Client_Level_6"},
{name:"Condor Building Contracting W.L.L", level:" Client_Level_6"},
{name:"Ocean Offshore", level:"  Client_Level_6"},
{name:"Precious Trading &Contracting. W.L.L", level:"  Client_Level_6"},
{name:"Nimr Oil Well Maintenance Co", level:"  Client_Level_6"},
{name:"Al-Tahdi Trading", level:"  Client_Level_6"},
{name:"Qatar Fabrication Company Wll", level:" Client_Level_6"},
{name:"Carnegie Mellon University", level:"  Client_Level_6"},
{name:"Al-Azzbi Electrical And Trading Co. Wll", level:" Client_Level_6"},
{name:"Dr.Noor", level:" Client_Level_6"},
{name:"Cdc- Takenaka Joint Venture", level:" Client_Level_6"},
{name:"Aecom Middle East Limited", level:" Client_Level_6"},
{name:"Geo-Green", level:" Client_Level_6"},
{name:"Sahara Oil Field", level:"  Client_Level_6"},
{name:"Star Techno Trading And Contracting", level:" Client_Level_6"},
{name:"Qafac", level:" Client_Level_6"},
{name:"Fentek Trading", level:"  Client_Level_6"},
{name:"Hamad Medical Corporation - Dr. Jasneed", level:" Client_Level_6"},
{name:"Nasser S Al Hajri Co. Ltd", level:" Client_Level_6"},
{name:"Cash - Thomas Trading", level:" Client_Level_6"},
{name:"Pemco", level:" Client_Level_6"},
{name:"Radisson Blu Hotel", level:"  Client_Level_6"},
{name:"Mey", level:" Client_Level_6"},
{name:"E Plug", level:"  Client_Level_6"},
{name:"Techno-Trust", level:"  Client_Level_6"},
{name:"Al Azbi", level:" Client_Level_6"},
{name:"Mohamed", level:" Client_Level_6"},
{name:"Market Hub", level:"  Client_Level_6"},
{name:"Cdct", level:"  Client_Level_6"},
{name:"Nimr Oil Well", level:" Client_Level_6"},
{name:"Al-Qamra Holding", level:"  Client_Level_6"},
{name:"Hameed", level:"  Client_Level_6"},
{name:"Sigma Metal Industries", level:"  Client_Level_6"},
{name:"Sulaithi", level:"  Client_Level_6"},
{name:"Majestic International", level:"  Client_Level_6"},
{name:"Qatar Construction Technique Wll(Qctc)", level:" Client_Level_6"},
{name:"Strukton Construction And Trading Wll", level:" Client_Level_6"},
{name:"Olympic Trading And Contracting", level:" Client_Level_6"},
{name:"United Safety", level:" Client_Level_6"},
{name:"Fentek", level:"  Client_Level_6"},
{name:"Fircroft Qatr Llc", level:" Client_Level_6"},
{name:"Pemco Inspection", level:"  Client_Level_6"},
{name:"Condor", level:"  Client_Level_6"},
{name:"Tools & Bolts Hardwares", level:" Client_Level_6"},
{name:"Imalco", level:"  Client_Level_6"},
{name:"Unitech", level:" Client_Level_6"},
{name:"Al Thadi Trading", level:"  Client_Level_6"},
{name:"Meremac Trading", level:" Client_Level_6"},
{name:"Keppel Seghers", level:"  Client_Level_6"},
{name:"Multi Link Trading", level:"  Client_Level_6"},
{name:"Best Choice Trading And Contracting", level:" Client_Level_6"},
{name:"Tobit Trading & Contracting Co. Wll", level:" Client_Level_6"},
{name:"Dexter Calivara", level:" Client_Level_6"},
{name:"Sulaiman", level:"  Client_Level_6"},
{name:"Pipeline Supplies Services Wll", level:"  Client_Level_6"},
{name:"Facilities Management & Maintenance, Llc ", level:" Client_Level_6"},
{name:"Instrol Llc", level:" Client_Level_6"},
{name:"Astrea Group ", level:" Client_Level_6"},
{name:"Mct", level:" Client_Level_6"},
{name:"Gulf Supplies & Commercial Services Llc", level:" Client_Level_6"},
{name:"Seaway Trading Co", level:"  Client_Level_6"},
{name:"Porsche", level:" Centre Doha Client_Level_6"},
{name:"Seaway", level:"  Client_Level_6"},
{name:"Future Light Trading", level:"  Client_Level_6"},
{name:"Marjorie", level:"  Client_Level_6"},
{name:"Aqua Chemie Gt Llc", level:"  Client_Level_6"},
{name:"Gulf Agency Company", level:" Client_Level_6"},
{name:"Adastra", level:" Client_Level_6"},
{name:"Dr. Jasneed", level:" Client_Level_6"},
{name:"Al Jazeera Trading", level:"  Client_Level_6"},
{name:"Tools & Bolts", level:" Client_Level_6"},
{name:"Veolia-Water", level:"  Client_Level_6"},
{name:"Como", level:"  Client_Level_6"},
{name:"Sr International", level:"  Client_Level_6"},
{name:"Fircroft", level:"  Client_Level_6"},
{name:"Muehlhan Dehan Qatar", level:"  Client_Level_6"},
{name:"Elen", level:"  Client_Level_6"},
{name:"Hayat Communications", level:"  Client_Level_6"},
{name:"United Equipment Group", level:"  Client_Level_6"},
{name:"Parsons", level:" Client_Level_6"},
{name:"Garden House Center", level:" Client_Level_6"},
{name:"Alrasheed", level:" Client_Level_6"},
{name:"Condor Building Contracting", level:" Client_Level_6"},
{name:"Qatar Building Company", level:"  Client_Level_6"},
{name:"Al-Bandari", level:"  Client_Level_6"},
{name:"Power Wing Management And Trasnport", level:" Client_Level_6"},
{name:"K3", level:"  Client_Level_6"},
{name:"Hamilton Trading & Contracting Wll", level:"  Client_Level_6"},
{name:"Patsco", level:"  Client_Level_6"},
{name:"Ceprotec Trading ", level:" Client_Level_6"},
{name:"Muehlhan Qatar", level:"  Client_Level_6"},
{name:"Gs Engineering And Construction", level:" Client_Level_6"},
{name:"Qd-Sbg O&M (Fm0003)", level:" Client_Level_6"},
{name:"Mekdam Technology", level:" Client_Level_6"},
{name:"Pipeline", level:"  Client_Level_6"},
{name:"Cosmos Trading", level:"  Client_Level_6"},
{name:"World Of Materials", level:"  Client_Level_6"},
{name:"Amol Deshpande Abb Llc", level:"  Client_Level_6"},
{name:"Jbk Control W.L. L", level:"  Client_Level_6"},
{name:"Anthos Eliades Abb Llc", level:"  Client_Level_6"},
{name:"Lemaire Abb Llc", level:" Client_Level_6"},
{name:"Noorul Hameed", level:" Client_Level_6"},
{name:"Dun", level:" Client_Level_6"},
{name:"Suliaman", level:"  Client_Level_6"},
{name:"Qatar Communication", level:"  Client_Level_6"},
{name:"Fircroft Group", level:"  Client_Level_6"},
{name:"Senthil Vel", level:" Client_Level_6"},
{name:"Medgulf Construction Company Wll", level:"  Client_Level_6"},
{name:"Hbk", level:" Client_Level_6"},
{name:"Safe Guard Trading& Cont Wll", level:"  Client_Level_6"},
{name:"Hydroserv", level:" Client_Level_6"},
{name:"Ann Villanueva", level:"  Client_Level_6"},
{name:"Luqman", level:"  Client_Level_6"},
{name:"Psjv", level:"  Client_Level_6"},
{name:"Cash Purchase", level:" Client_Level_6"},
{name:"Cash Invoice", level:"  Client_Level_6"},
{name:"Artbeton Trading", level:"  Client_Level_6"},
{name:"Petro Technical Supply Service", level:"  Client_Level_6"},
{name:"Cosmo Triding", level:" Client_Level_6"},
{name:"Star Techno Trading And Construction", level:"  Client_Level_6"},
{name:"Signet", level:"  Client_Level_6"},
{name:"Moad", level:"  Client_Level_6"},
{name:"Manooj", level:"  Client_Level_6"},
{name:"Aa-Engineering", level:"  Client_Level_6"},
{name:"Hamad Medical Corporation - Dr. Shanavas", level:"  Client_Level_6"},
{name:"Apollo Interprise", level:" Client_Level_6"},
{name:"Marubeni", level:"  Client_Level_6"},
{name:"Muehlhan Dehan", level:"  Client_Level_6"},
{name:"Muehlhan Dehan Qatar W.L.L ", level:" Client_Level_6"},
{name:"Elsewedy Cable", level:"  Client_Level_6"},
{name:"Multicon Trading Co", level:" Client_Level_6"},
{name:"Makram", level:"  Client_Level_6"},
{name:"Performance Marine", level:"  Client_Level_6"},
{name:"Dunn", level:"  Client_Level_6"},
{name:"Behzad Trading Enterprises", level:"  Client_Level_6"},
{name:"Huawei", level:"  Client_Level_6"},
{name:"Pendex Trading", level:"  Client_Level_6"},
{name:"Liya", level:"  Client_Level_6"},
{name:"Al Musairi Trading Wll", level:"  Client_Level_6"},
{name:"Rajuul", level:"  Client_Level_6"},
{name:"Al-Nahda", level:"  Client_Level_6"},
{name:"Urbacon", level:" Client_Level_6"},
{name:"Qdc", level:" Client_Level_6"},
{name:"Sealand Marine", level:"  Client_Level_6"},
{name:"Randy", level:" Client_Level_6"},
{name:"Qstp", level:"  Client_Level_6"},
{name:"Bangkok Chaina", level:"  Client_Level_6"},
{name:"Elsewedy", level:"  Client_Level_6"},
{name:"Austin Chako - Ooredoo", level:"  Client_Level_6"},
{name:"Aida Trading", level:"  Client_Level_6"},
{name:"Marvel Star", level:" Client_Level_6"},
{name:"Sidra Medicine - Dr. Mannu", level:"  Client_Level_6"},
{name:"Rahees", level:"  Client_Level_6"},
{name:"Siko", level:"  Client_Level_6"},
{name:"Arnold ", level:" Client_Level_6"},
{name:"Ismail", level:"  Client_Level_6"},
{name:"Becon Trading", level:" Client_Level_6"},
{name:"Swift Trading", level:" Client_Level_6"},
{name:"Waseem", level:"  Client_Level_6"},
{name:"Ultimate Uniforms", level:" Client_Level_6"},
{name:"Vasco Build Mark", level:"  Client_Level_6"},
{name:"Sibi", level:"  Client_Level_6"},
{name:"Madhas", level:"  Client_Level_6"},
{name:"Solution Technology", level:" Client_Level_6"},
{name:"Noor", level:"  Client_Level_6"},
{name:"Qatar Communication", level:" Client_Level_6"},
{name:"Bonern Al Fadar", level:" Client_Level_6"},
{name:"Host International", level:"  Client_Level_6"},
{name:"Mct Pest Control", level:"  Client_Level_6"},
{name:"Olympic", level:" Client_Level_6"},
{name:"Alwatan", level:" Client_Level_6"},
{name:"Maples Safety & Industrial Solutions", level:"  Client_Level_6"}
  ]

  const categoryss_level = [
{name: "BODY PROTECTION-PANT & SHIRT", level:"Cat_Level_1"},
{name: "ANTI CORROSION", level:"  Cat_Level_1"},
{name: "No Product_data", level:" Cat_Level_1"},
{name: "FOOT PROTECTION-SAFETY BOOT", level:" Cat_Level_1"},
{name: "EYE PROTECTION", level:"  Cat_Level_1"},
{name: "BODY PROTECTION-DISPOSABLE COVERALL", level:" Cat_Level_1"},
{name: "HEAD PROTECTION", level:" Cat_Level_2"},
{name: "RESPIRATORY PROTECTION", level:"  Cat_Level_2"},
{name: "BODY PROTECTION-COVERALL", level:"  Cat_Level_2"},
{name: "MISCELLANEOUS", level:" Cat_Level_2"},
{name: "BODY PROTECTION-SAFETY VEST", level:" Cat_Level_2"},
{name: "EAR PROTECTION", level:"  Cat_Level_3"},
{name: "GLOVES", level:"  Cat_Level_3"},
{name: "WELDING PROTECTION", level:"  Cat_Level_3"},
{name: "BODY PROTECTION", level:" Cat_Level_3"},
{name: "SAFETY SHOES", level:"  Cat_Level_3"},
{name: "BODY PROTECTION-WINTER JACKET", level:" Cat_Level_3"},
{name: "FALL PROTECTION", level:" Cat_Level_3"},
{name: "OIL SPILL", level:" Cat_Level_4"},
{name: "CHEMICAL", level:"  Cat_Level_4"},
{name: "FOOT PROTECTION", level:" Cat_Level_4"},
{name: "FOOT PROTECTION-SAFETY SHOES", level:"  Cat_Level_4"},
{name: "GAS DETECTION", level:" Cat_Level_4"}
  ]

  const manufacture = [
{name: "CHARNAUD", level:"Manufacturer_Level_1"},
{name: "MISCELLANEUOS", level:" Manufacturer_Level_1"},
{name: "CORTEC", level:"  Manufacturer_Level_1"},
{name: "No Product_data", level:" Manufacturer_Level_1"},
{name: "PYRAMEX", level:" Manufacturer_Level_1"},
{name: "CENTURION", level:" Manufacturer_Level_1"},
{name: "SHOWA", level:" Manufacturer_Level_1"},
{name: "SCOTT", level:" Manufacturer_Level_1"},
{name: "HONEYWELL", level:" Manufacturer_Level_2"},
{name: "DERIKDUCK", level:" Manufacturer_Level_2"},
{name: "3M", level:"  Manufacturer_Level_3"},
{name: "MSA", level:" Manufacturer_Level_3"},
{name: "MISCELLANEOUS", level:" Manufacturer_Level_3"},
{name: "DUPONT", level:"  Manufacturer_Level_3"},
{name: "FOOTSURE", level:"  Manufacturer_Level_3"},
{name: "MECHANIX", level:"  Manufacturer_Level_3"},
{name: "PROTECTA", level:"  Manufacturer_Level_3"},
{name: "TIMBERLAND", level:"  Manufacturer_Level_3"},
{name: "RINGERS", level:" Manufacturer_Level_3"},
{name: "DBI SALA", level:"  Manufacturer_Level_3"},
{name: "GRANBERG", level:"  Manufacturer_Level_3"},
{name: "FOSSELIQUITROL", level:"  Manufacturer_Level_3"},
{name: "RUBBEREX", level:"  Manufacturer_Level_3"},
{name: "GARMNEX", level:" Manufacturer_Level_3"},
{name: "LOTOMASTER", level:"  Manufacturer_Level_3"},
{name: "PERF", level:"  Manufacturer_Level_3"},
{name: "DELTA PLUS", level:"  Manufacturer_Level_3"}
]

	return (
        <Fragment>
         
         <div className="container-fluid">
         {isLoading ? (
          <Backdrop className={classes.circular} open={isLoading}>
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : (
            <div className="row">
             <div className="col-sm-12 col-xl-6">
                <div className="card card-absolute">
                    <div className="card-header bg-secondary">
                        <h5>Predict</h5>
                    </div>
                    <div className="card-body">
                    
		             <div className="col-md-12">
		              <Autocomplete 
                    freeSolo
                    options={client}
                    getOptionLabel={option => option.name}
                    value={cli_lvl}                      
                    onChange={(event, newValue) => {
                      setCli_lvl(newValue);
                    }}            
                    renderInput={params => (
                      <TextField {...params} label="Client" margin="normal" variant="outlined" helperText="Please select Client" fullWidth />
                    )}
                  />
		             </div>
                  <div className="col-md-12">
                      <Autocomplete
                        freeSolo
                        options={manufacture}
                        getOptionLabel={option => option.name}
                        value={mer_lvl}                    
                        onChange={(event, newValue) => {
                          setMer_lvl(newValue);
                        }}            
                        renderInput={params => (
                          <TextField {...params} label="Manufacture" margin="normal" variant="outlined" helperText="Please select Manufacture" fullWidth />
                        )}
                      />
                 </div>
                 <div className="col-md-12">
                      <Autocomplete
                        freeSolo
                        options={categoryss_level}
                        getOptionLabel={option => option.name}
                        value={cat_lvl}                      
                        onChange={(event, newValue) => {
                          setCat_lvl(newValue);
                        }}            
                        renderInput={params => (
                          <TextField {...params} label="Category" margin="normal" variant="outlined" helperText="Please select Category" fullWidth />
                        )}
                      />
                 </div>

                 <div className="card-footer text-center">
                      <button className="btn btn-pill btn-secondary-gradien btn-lg" type="button" onClick={handleSubmit}>Predict</button>
                    </div>
		             
                    </div>
    
                </div>
             </div>
            </div>
            )}
          </div> 
        
        
          </Fragment>
    );
}
export default InvoiceProcessing;