import React, { Fragment, useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TextField, FormHelperText, MenuItem, TablePagination } from '@material-ui/core';
import {Autocomplete} from '@material-ui/lab';
import {  getPldata, deletePldata} from '../../actions/asyncActions';

const elementStyle ={
    border:'solid',
    borderRadius:'10px',
    position:'relative',
    left:'1vh',
    height:'6vh',
    width:'23vh',
    marginTop:'1vh',
    marginBottom:'1vh',
    textAlign: 'center'
  }

const Installation = () => {
    const user = JSON.parse(localStorage.getItem("m_users"));
    const group_id = user.group_id;
    const [pldata, setPldata] = useState([]);
    const [filteredpl, setFilteredPl] = useState([]);
    const [search, setSearch] = useState('');
    const [pldatamodal, setplDataModal] = useState();
    const [plDataFileTransactions, setplDataFileTransactions] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    

    useEffect( () => {
      getPldata()
      .then(resp => setPldata(resp.data))
    }, []);

     const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    const updateSearch = (event) => {
    setSearch(event.target.value.substr(0,20));
  }

  useEffect(() => {
    setFilteredPl(
     pldata.filter(n => {
      return n.month.toLowerCase().includes(search.toLowerCase());
     })
    )
  }, [search, pldata])


  const handleViewTranscaction = (filename) => {
   setplDataModal(!pldatamodal)
   setplDataFileTransactions(
     filteredpl.filter(f => f.pl_filename == filename)
    )
  }


    const handleDeleteItem = (filename) => {
      const data ={
        filename: filename
      }
      deletePldata(data)
      .then(resp => {
        getPldata()
        .then(resp => {
          setPldata(resp.data);
        });
      })
      .catch(err => {
        console.log("Error", err);
      })
    };

     const aa = filteredpl.reduce((unique, s) => (unique.includes(s.pl_filename) ? unique : [...unique, s.pl_filename]),[],);
    
    


        return (
            <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-block row">
                                <div className="col-sm-12 col-lg-12 col-xl-12">
                                    <input type="text" placeholder="Search Items" style={elementStyle} value={search}
                                       onChange={e => setSearch(e.target.value)} />
                                      
                                    <div className="table-responsive-xl">
                                        <table className="table table-bordered table-hover">
                                            <thead className="table-primary">
                                                <tr className="text-center">
                                                <th scope="col">Year</th>
                                                    <th scope="col">Month</th>
                                                    <th scope="col">File</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                  {aa.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((n, i)=> {
                                                  const sa = filteredpl.find(z => z.pl_filename === n);
                                                  const filename = sa && sa.pl_filename;
                                                  const Month = sa && sa.month; 
                                                  const year = sa && sa.year;
                                                  return (
                                                    <tr className="table-sm">
                                                    <td>{year}</td>
                                                     <td>{Month}</td>
                                                     <td>{filename}</td>
                                                     <td>
                                                      <i className="fa fa-eye" onClick={() => handleViewTranscaction(filename)} style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e' }}
                                                      ></i>
                                                      <i className="fa fa-trash" onClick={() => handleDeleteItem(filename)} style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e' }}
                                                      ></i>
                                                     </td>
                                                    </tr>
                                                  );
                                                })
                                              }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                  <div className="card">
                                    <div className="card-block row">
                                      <div className="col-sm-6 col-lg-6 col-xl-6 m-t-15">
                                      </div>
                                      <div className="col-sm-6 col-lg-6 col-xl-6">
                                        <TablePagination
                                          rowsPerPageOptions={[5, 10, 25]}
                                          component="div"
                                          count={aa.length}
                                          rowsPerPage={rowsPerPage}
                                          page={page}
                                          onChangePage={handleChangePage}
                                          onChangeRowsPerPage={handleChangeRowsPerPage}
                                         />
                                      </div>
                                    </div>
                                  </div>
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

             <Modal className="modal-dialog modal-xl" isOpen={pldatamodal} toggle={handleViewTranscaction}>
                <ModalHeader toggle={handleViewTranscaction}>Transactions List</ModalHeader>
                <ModalBody>
                <div className="table-responsive">
                  <table className="table table-bordered table-hover">
                                            <thead className="table-primary">
                                                <tr className="text-center">
                                                   <th scope="col">Account</th>
                                                    <th scope="col">Account_type</th>
                                                    <th scope="col">Account_name</th>
                                                    <th scope="col">Acc_sub_group</th>
                                                    <th scope="col">Amount</th>
                                                    <th scope="col">Percentage</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                  {plDataFileTransactions.map((n, i)=> {
                                                  return (
                                                    <tr className="table-sm">
                                                     <td>{n.account}</td>
                                                     <td>{n.account_type}</td>
                                                     <td>{n.account_name}</td>
                                                     <td>{n.account_sub_group}</td>
                                                     <td>{parseFloat(n.amount).toFixed(2).toLocaleString('en', {minimumFractionDigits: 2})}</td>
                                                     <td>{parseFloat(n.pct_ge).toFixed(2).toLocaleString('en', {minimumFractionDigits: 2})}</td> 
                                                    </tr>
                                                  );
                                                })
                                              }
                                            </tbody>
                                        </table>
                                        </div>
                </ModalBody>
                <ModalFooter>
                    
                    <Button color="secondary" onClick={handleViewTranscaction}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </Fragment>
        );
}

export default Installation;