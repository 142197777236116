import React from 'react';
import {AppBar, Button, Card, CardActions, CardContent, CardMedia, CssBaseline, Grid, Toolbar, Typography, Container, Link} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import dash from './dash.jpg';
import axios from 'axios';

//import {  sendSaleid } from '../../actions/asyncActions';

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', 
    //height: 32
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}));


   
const user = JSON.parse(localStorage.getItem("m_users"));
const user_id = user && user.id || [];


//const onlineurl = "http://127.0.0.1:8000/dashapi";
const onlineurl = "http://biewu.nu-pie.com/dashapi";
const user_role = user && user.role ;


let cards = [];
if(user_role == "salesman") {
  cards = [{ nick:'Salesman', role:'salesman', id:2, name:'Ahmed', link: `${onlineurl}/salesmanind`, img_link:'https://images.unsplash.com/photo-1543286386-713bdd548da4?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80'}]
}
else {
 cards = [
{nick:'Revenue Overview', link: `${onlineurl}/revenue`, img_link: 'https://images.unsplash.com/photo-1551288049-bebda4e38f71?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80'},
{nick:'Expense Overview', link: `${onlineurl}/expense`, img_link: 'https://images.unsplash.com/photo-1592495989226-03f88104f8cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1086&q=80'},
{nick:'Salesman Overview', link: `${onlineurl}/salesman`, img_link: 'https://images.unsplash.com/photo-1535320903710-d993d3d77d29?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80'}
];
}

 
// let userCards = [];
// if(user_role == "admin") {
//   userCards = []
// }
// else {
// const userCards = [
// { nick:'Salesman', role:'salesman', id:2, name:'Ahmed', link: `${onlineurl}/salesmanind`, img_link:'https://images.unsplash.com/photo-1543286386-713bdd548da4?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80'}
// ];
// }

 

//const filterUser = userCards.find(x=>x.id === user_id);
//const filterUser = userCards;

//const joinCards = cards.concat(filterUser || []);
const joinCards = cards || [];


// const alertImg = () => {
//   const onlineurl1 = "http://127.0.0.1:8888/salesmanapi";
//   //const onlineurl1 = "http://biewu.nu-pie.com/salesmanapi";
//   const user = JSON.parse(localStorage.getItem("m_users"));
//   const userid = user.id;
//   const username = user.name;
//   const data = JSON.stringify({"id":userid});
   
// fetch(`${onlineurl1}/salid/`, {
//   method: 'POST',
//   headers: {
//     'Content-Type': 'application/json; charset=utf-8',
//   },
//   body: data,
// })
// .then(response => response.json())
// .then(data => {
//   console.log('Success:', data);
// })
// .catch((error) => {
//   console.error('Error:', error);
// });
// };


const alertImg = () => {
 const user = JSON.parse(localStorage.getItem("m_users"));
 const userid = user && user.id;
    return axios
        .post(
            'http://127.0.0.1:8888/salesmanapi/salid', {
                salesmanid: userid
            }, {
                headers: { "Content-type": "application/json" }
            })
        .then((res) => {
            console.log(res)
        })
}

export default function Album() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <CssBaseline />
      <main>
        <Container className={classes.cardGrid} maxWidth="md">
          {/* End hero unit */}
          <Grid container spacing={4}>
            {joinCards.map((card) => (
              <Grid item key={card} xs={12} sm={6} md={4}>

                <Card className={classes.card}>
                  <CardMedia className={classes.cardMedia}
                    image={card.img_link}
  
                  />

                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h5" component="h2">
                      {card.nick}
                    </Typography>
                  </CardContent>
                  <CardActions>
                  <div>
                   <a href={card.link}>
                        <Button>
                              View DashBoard
                        </Button>
                    </a>  
                    </div>
                   
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </main>
    </React.Fragment>
  );
}