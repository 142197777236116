import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { TablePagination } from '@material-ui/core';
import { getAllUser, updateUsers, postUsers, deleteUsers } from '../../actions/asyncActions';

const MyUser = () => {
    const user = JSON.parse(localStorage.getItem("np_users"));
   
    const [person, setPerson] = useState([]);
    
    const initialFormState = { id: '', name:'', phone:'',  password:'', email:'', role:''}
    const [ cu_user, setCurrentUser ] = useState(initialFormState)
    const [modal, setModal] = useState();
    const [addmodal, setAddModal] = useState();
    const [values, setValues] = useState({});
    const [bulk_upload, setUpload] = useState();
    const [uploadfile, setFile] = useState([]);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    useEffect( () => {
     getAllUser()
     .then(resp => {
      setPerson(resp.data);
     })
    }, []);

    const handleChange = event => {
        setValues({
          ...values,
          [event.target.name]: event.target.value
        });
    };

    const handleInputChange = event => {
        const { name, value } = event.target
        setCurrentUser({ ...cu_user, [name]: value })
    }

    const addRow = () => {
     setAddModal(!addmodal)
    }

    const editRow = user => {
     setModal(!modal)
     setCurrentUser({ id: user.id, name: user.name, phone: user.phone, 
       password:user.password, email:user.email, role:user.role })
    }

    const bulkUpload = () => {
     setUpload(!bulk_upload)
    }

    const onFileDrop = (files) => {
      setFile(document.getElementById('file').files[0]);
    }

    // const handleUploadSubmit = async (event) => {
    //   const user = JSON.parse(localStorage.getItem("np_users"));
    //   const formData = new FormData()
    //   formData.append( 'file', uploadfile )
    //   formData.append('lab_id', user.lab_id)
    //   bulkUserUpload(formData)
    //   .then(resp => {
    //       getAllUser()
    //       .then(resp => setPerson(resp.data))
    //       setUpload(!bulk_upload)
    //     })
    //     .catch(err => {
    //       console.log("Error", err);
    //   })
    // }

    const handleSubmit = () => {
        const userss = JSON.parse(localStorage.getItem("np_users"));
        const adduser = {
          name: values.name,
          phone: values.phone,
          email: values.email,
          password: values.password,
          role: values.role,
        }
        postUsers(adduser)
        .then(resp => {
            getAllUser()
            .then(resp => setPerson(resp.data))
            setAddModal(!addmodal)
          })
          .catch(err => {
            console.log("Error", err);
        })
   }

   const handleUpdate = (id, data) => {
        const user = JSON.parse(localStorage.getItem("np_users"));
        const bbb = {
          id: id,
          name: data.name,
          phone: data.phone,
          password: data.password,
          email: data.email,
          role: data.role,
        }
        updateUsers(bbb)
        .then(resp => {
              getAllUser()
              .then(resp => setPerson(resp.data))
            })
            .catch(err => {
              console.log("Error", err);
            })
        setModal(!modal)
    }

    const deleteRow = (n) => {
      const aaa = {
        id: n.id
      }
      deleteUsers(aaa)
      .then(resp => {
        getAllUser()
        .then(resp => {
          setPerson(resp.data);
        });
      })
      .catch(err => {
        console.log("Error", err);
      })
    };

        return (
            <Fragment>
            <Breadcrumb title="All Users" parent="Users" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-block row">
                                <div className="col-sm-12 col-lg-12 col-xl-12">
                                    <div className="table-responsive">
                                        <table className="table table-sm table-bordered table-hover">
                                            <thead className="table-primary">
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Name</th>
                                                    <th scope="col">Phone</th>
                                                    <th scope="col">Email</th>
                                                    <th scope="col">Role</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {person.filter(s => s.role !== 'super_admin' )
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((n, i)=> {
                                                  return (
                                                    <tr>
                                                     <th scope="row">{i+1}</th>
                                                     <td>{n.name}</td>
                                                     <td>{n.phone}</td>
                                                     <td>{n.email}</td>
                                                     <td>{n.role}</td>
                                                     <td>
                                                      <i className="fa fa-edit" onClick={() => editRow(n)} style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e' }}
                                                      ></i>
                                                      <i className="fa fa-trash" onClick={() => deleteRow(n)} style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e' }}
                                                      ></i>
                                                     </td>
                                                    </tr>
                                                  );
                                                })
                                              }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-block row">
                                <div className="col-sm-6 col-lg-6 col-xl-6">
                                 <button className="btn btn-pill btn-primary btn-sm" type="button" onClick={() => addRow()}>
                                   Add User
                                  </button>&nbsp;
                                 
                                </div>
                                <div className="col-sm-6 col-lg-6 col-xl-6">
                                  <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={person.filter(s => s.role !== 'super_admin' ).length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                   />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* < !-- Using Form Modal for Add Users --> */}
            <Modal isOpen={addmodal} toggle={addRow}>
                <ModalHeader toggle={addRow}>Add User</ModalHeader>   
                <ModalBody>
                    <form>
                        <div className="form-row">
                            <div className="col-md-6 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Full name:</label>
                              <input className="form-control" name="name" type="text" placeholder="Full name" 
                               value={values.name} onChange={handleChange}/>
                            </div>
                            <div className="col-md-6 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Phone:</label>
                              <input className="form-control" name="phone" type="text" placeholder="Phone" 
                               value={values.phone} onChange={handleChange}/>
                            </div>
                            <div className="col-md-6 mb-3">
                               <label className="col-form-label" htmlFor="message-text">Email:</label>
                              <input className="form-control" name="email" type="email" placeholder="Email" 
                               value={values.email} onChange={handleChange}/>
                            </div>
                            <div className="col-md-6 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Password:</label>
                              <input className="form-control" name="password" type="password" placeholder="Password" 
                               value={values.password} onChange={handleChange}/>
                            </div>
                            <div className="col">
                              <div className="form-group m-t-15 m-checkbox-inline mb-0 custom-radio-ml">
                                <label className="col-form-label" htmlFor="message-text">Role:</label>
                                <div className="radio radio-primary">
                                  <input id="radioinline1" type="radio" name="role" value="admin" onChange={handleChange} />
                                  <label className="mb-0" for="radioinline1">Admin</label>
                                </div>
                                <div className="radio radio-primary">
                                  <input id="radioinline2" type="radio" name="role" value="creator" onChange={handleChange} />
                                  <label className="mb-0" for="radioinline2">Creator</label>
                                </div>
                                <div className="radio radio-primary">
                                  <input id="radioinline3" type="radio" name="role" value="explorer" onChange={handleChange} />
                                  <label className="mb-0" for="radioinline3">Explorer</label>
                                </div>
                                 <div className="radio radio-primary">
                                  <input id="radioinline4" type="radio" name="role" value="salesman" onChange={handleChange} />
                                  <label className="mb-0" for="radioinline4">Salesman</label>
                                </div>
                              </div>
                              
                            </div>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    
                    <Button color="secondary" onClick={addRow}>Cancel</Button>
                    <Button color="primary" onClick={handleSubmit} >Submit</Button>
                </ModalFooter>
            </Modal>

            {/* < !-- Using Form Modal for Edit Users --> */}
            <Modal isOpen={modal} toggle={editRow}>
                <ModalHeader toggle={editRow}>Edit User</ModalHeader>   
                <ModalBody>
                    <form>
                        <div className="form-row">
                            <div className="col-md-6 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Full name:</label>
                              <input className="form-control" name="name" type="text" placeholder="Full name" 
                               value={cu_user.name} onChange={handleInputChange}/>
                            </div>
                            <div className="col-md-6 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Phone:</label>
                              <input className="form-control" name="phone" type="number" placeholder="Phone" 
                               value={cu_user.phone} onChange={handleInputChange}/>
                            </div>
                            <div className="col-md-6 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Email:</label>
                              <input className="form-control" name="email" type="email" placeholder="Email" 
                               value={cu_user.email} onChange={handleInputChange}/>
                            </div>
                            <div className="col-md-6 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Password:</label>
                              <input className="form-control" name="password" type="password" placeholder="Password" 
                               value={cu_user.password} onChange={handleInputChange}/>
                            </div>
                            <div className="col-md-12 mb-3">
                              <div className="form-group m-t-15 m-checkbox-inline mb-0 custom-radio-ml">
                                <label className="col-form-label" htmlFor="message-text">Role:</label>
                                <div className="radio radio-primary">
                                  <input id="radioinline1" type="radio" name="role" value="admin" onChange={handleInputChange} />
                                  <label className="mb-0" for="radioinline1">Admin</label>
                                </div>
                                <div className="radio radio-primary">
                                  <input id="radioinline2" type="radio" name="role" value="creator" onChange={handleInputChange} />
                                  <label className="mb-0" for="radioinline2">Creator</label>
                                </div>
                                <div className="radio radio-primary">
                                  <input id="radioinline3" type="radio" name="role" value="explorer" onChange={handleInputChange} />
                                  <label className="mb-0" for="radioinline3">Explorer</label>
                                </div>
                                <div className="radio radio-primary">
                                  <input id="radioinline4" type="radio" name="role" value="salesman" onChange={handleInputChange} />
                                  <label className="mb-0" for="radioinline4">Explorer</label>
                                </div>
                                
                              </div>
                              
                            </div>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    
                    <Button color="secondary" onClick={editRow}>Cancel</Button>
                    <Button color="primary" onClick={ () => handleUpdate(cu_user.id, cu_user)} >Update</Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={bulk_upload} toggle={bulkUpload}>
                <ModalHeader toggle={bulkUpload}>Upload</ModalHeader>
                <ModalBody>
                  <div className="input-cropper col-md-9 mb-3">
                      <input id="file" type="file" onChange={onFileDrop} />
                  </div>
                </ModalBody>
                <ModalFooter>
                    
                    <Button color="secondary" onClick={bulkUpload}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </Fragment>
        );
}

export default MyUser;