import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { forwardRef } from 'react';
import {editIndInvoiceItems, postIndInvoiceItems2 } from '../../actions/asyncActions';

const Table = ({ tableData, comonscol, invoice_id }) => {
  const user = JSON.parse(localStorage.getItem("m_users"));
  const group_id = user.group_id;
  const user_id = user.id;

  const [gridData, setGridData] = useState(tableData);
  const [columns, setcolumns] = useState(comonscol);
  const [invoiceId, setInvoiceId] = useState(invoice_id);
  const [invoiceItems, setInvItems] = useState([]);
  const [fetchInvoice, setFetchInvoice] = useState([]);

  useEffect(() => {
    gridData.resolve();
    setcolumns(comonscol);
    console.log("RESOLVE AT:", gridData.updatedAt);
  }, [gridData, comonscol]);

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const onRowAdd = newData =>
  new Promise((resolve, reject) => {
    const data = [...gridData.data];
    data.push(newData);
    const updatedAt = new Date();
    setGridData({ ...gridData, data, updatedAt, resolve });
    //postIndInvoiceItems(newData)
    postIndInvoiceItems2({newData, invoiceId, user_id, group_id})
    .then(resp => {
      setFetchInvoice(resp.data)
      const aaa = {
        id: resp.data.id,
        invoice_id: data[0].invoice_id,
        user_id: data[0].user_id,
        group_id: data[0].group_id
      }
    editIndInvoiceItems(aaa)
     })
    
});
  
const onRowUpdate = (newData, oldData) =>
  new Promise((resolve, reject) => {
    setcolumns([]);
    const { data } = gridData;
    const updatedAt = new Date();
    const index = data.indexOf(oldData);
    data[index] = newData;
    setGridData({ ...gridData, data, resolve, updatedAt });
    editIndInvoiceItems(newData)
  });
console.log("gridData", gridData);
const { data } = gridData;

  return (
    <div className="App">
      <MaterialTable
        icons={tableIcons}
        data={data}
        columns={columns}
        title="Invoice item details"
        editable={{
          isEditable: rowData => true,
          onRowAdd: onRowAdd,
          onRowUpdate: onRowUpdate,
        }}
      />
    </div>
  );
};

export default Table;
