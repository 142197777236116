import React from 'react';

   
const user = JSON.parse(localStorage.getItem("m_users"));
const user_id = user && user.id || [];
const pradeep = user_id.toString();
export default function Salesman() {
  

  return (
       <div>
        {pradeep}
      </div>
  );
}