import React, { Fragment, useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TextField, FormHelperText, MenuItem, TablePagination } from '@material-ui/core';
import {Autocomplete} from '@material-ui/lab';
import {  getStockdata, deleteStockdata} from '../../actions/asyncActions';
import Breadcrumb from '../../components/common/breadcrumb';
import { Home, Settings, MoreHorizontal  } from 'react-feather';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';

const elementStyle ={
    border:'solid',
    borderRadius:'10px',
    position:'relative',
    left:'1vh',
    height:'6vh',
    width:'23vh',
    marginTop:'1vh',
    marginBottom:'1vh',
    textAlign: 'center'
  }

const SalesData = () => {
    const user = JSON.parse(localStorage.getItem("m_users"));
    const group_id = user.group_id;
    const [stockdata, setStockdata] = useState([]);

    const [filteredstock, setFilteredStock] = useState([]);
     const [search, setSearch] = useState('');
    const [stockdatamodal, setstockDataModal] = useState();
    const [stockDataFileTransactions, setstockDataFileTransactions] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    useEffect( () => {
      getStockdata()
      .then(resp => setStockdata(resp.data))
    }, []);



    const updateSearch = (event) => {
    setSearch(event.target.value.substr(0,20));
  }

  useEffect(() => {
    setFilteredStock(
     stockdata.filter(n => {
      return n.quarter.toLowerCase().includes(search.toLowerCase());
     })
    )
  }, [search, stockdata])

     const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };


    const handleViewTranscaction = (filename) => {
   setstockDataModal(!stockdatamodal)
   setstockDataFileTransactions(
     filteredstock.filter(f => f.stock_filename == filename)
    )
  }


    const handleDeleteItem = (filename) => {
      const data ={
        filename: filename
      }
      deleteStockdata(data)
      .then(resp => {
        getStockdata()
        .then(resp => {
          setStockdata(resp.data);
        });
      })
      .catch(err => {
        console.log("Error", err);
      })
    };

     const stockdataset = filteredstock.reduce((unique, s) => (unique.includes(s.stock_filename) ? unique : [...unique, s.stock_filename]),[],);


        return (
            <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-block row">
                                <div className="col-sm-12 col-lg-12 col-xl-12">
                                 <input type="text" placeholder="Search Items" style={elementStyle} value={search}
                                       onChange={e => setSearch(e.target.value)} />
                                    <div className="table-responsive-xl">
                                        <table className="table table-bordered table-hover">
                                            <thead className="table-primary">
                                                <tr className="text-center">
                                                    <th scope="col">Year</th>
                                                    <th scope="col">Quarter</th>
                                                     <th scope="col">FileName</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {stockdataset.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((n, i)=> {
                                                  const sa = filteredstock.find(z => z.stock_filename === n);
                                                  const filename = sa && sa.stock_filename;
                                                  const quarter = sa && sa.quarter;
                                                  const year = sa && sa.year; 
                                                  return (
                                                    <tr className="table-sm">
                                                     <td>{year}</td>
                                                     <td>{quarter}</td>
                                                     <td>{filename}</td>
                                                     <td>
                                                      <i className="fa fa-eye" onClick={() => handleViewTranscaction(filename)} style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e' }}
                                                      ></i>
                                                      <i className="fa fa-trash" onClick={() => handleDeleteItem(filename)} style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e' }}
                                                      ></i>
                                                     </td>
                                                    </tr>
                                                  );
                                                })
                                              }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                  <div className="card">
                                    <div className="card-block row">
                                      <div className="col-sm-6 col-lg-6 col-xl-6 m-t-15">
                                      </div>
                                      <div className="col-sm-6 col-lg-6 col-xl-6">
                                        <TablePagination
                                          rowsPerPageOptions={[5, 10, 25]}
                                          component="div"
                                          count={stockdataset.length}
                                          rowsPerPage={rowsPerPage}
                                          page={page}
                                          onChangePage={handleChangePage}
                                          onChangeRowsPerPage={handleChangeRowsPerPage}
                                         />
                                      </div>
                                    </div>
                                  </div>
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

             <Modal className="modal-dialog modal-md" isOpen={stockdatamodal} toggle={handleViewTranscaction}>
                <ModalHeader toggle={handleViewTranscaction}>Transactions List</ModalHeader>
                <ModalBody>
                <div className="table-responsive">
                  <table className="table table-bordered table-hover">
                                            <thead className="table-primary">
                                                <tr className="text-center">
                                                     <th scope="col">Manufacturer</th>
                                                    <th scope="col">Category</th>
                                                    <th scope="col">Part_number</th>
                                              
                                                    <th scope="col">Avg_ageing_days</th>
                                                     <th scope="col">Stock_age_class</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                  {stockDataFileTransactions.map((n, i)=> {
                                                  return (
                                                    <tr className="table-sm">
                                            
                                                      <td>{n.manufacturer}</td>
                                                     <td>{n.category}</td>
                                                     <td>{n.part_number}</td>
                                                     <td>{n.avg_ageing_days}</td>
                                                     <td>{n.stock_age_class}</td>

                                                    </tr>
                                                  );
                                                })
                                              }
                                            </tbody>
                                        </table>
                                        </div>
                </ModalBody>
                <ModalFooter>
                    
                    <Button color="secondary" onClick={handleViewTranscaction}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </Fragment>
        );
}

export default SalesData;