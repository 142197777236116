import axios from 'axios';

let baseURL = 'http://127.0.0.1:9009/api';

if(process.env.NODE_ENV !== "development") {
  baseURL = "/api";
}
const instance = axios.create({
   withCredentials: true
 });

const makeRequest = (type, url, data) => {
  return instance[type](baseURL+url, data)
}

export const signinUser2 = (email, password) => {
  return makeRequest('post', '/login', {email, password})
  .then(resp => {
    if(resp.data){
      localStorage.setItem("s_token", resp.data.token);
      localStorage.setItem("m_users", JSON.stringify(resp.data.user));
      return resp.data;
    }
  })
}


/*** Users ***/
export const getAllUser = () => {
 return makeRequest('get', '/users');
}
export const getUser = () => {
  return makeRequest('get', '/users');
}

export const getUserdetails = (user_id) => {
  return makeRequest('get', `/users/${user_id}`);
}
export const postUsers = (data) => {
  return makeRequest('post', '/users', data);
}
export const editUsers = (data) => {
  return makeRequest('put', '/users', data);
}
export const updateUsers = (data) => {
  return makeRequest('put', '/users', data);
}
export const deleteUsers = (data) => {
  return makeRequest('delete', '/users', {data: data});
}
export const apiCheck = (data) => {
  return makeRequest('post', '/auth', data);
}


/* Forgot Password  */

export const sendEmaillink = (data) => {
return makeRequest('post', '/forgotpassword', data);
}

export const sendresettoken = (data) => {
return makeRequest('post', '/resetpassword', data);
}

export const updatepaswordviaemail = (data) => {
  return makeRequest('post', '/updatepasswordviaemail', data)
}




/* pldata Apis */ 

export const getPldata = () => {
  return makeRequest('get', '/pldata');
}

export const deletePldata = (data) => {
  return makeRequest('delete', '/pldata', {data: data});
}


/* Stock Apis */ 

export const getStockdata = () => {
  return makeRequest('get', '/stockdata');
}

export const deleteStockdata = (data) => {
  return makeRequest('delete', '/stockdata', {data: data});
}

export const Stockscript = (data) => {
  return makeRequest('post', '/stockscript', data)
}

export const Predictscript = (data) => {
  return makeRequest('post', '/predictscript', data)
}

/* Profit Apis */ 

export const getProfitdata = () => {
  return makeRequest('get', '/profitdata');
}

export const deleteProfitdata = (data) => {
  return makeRequest('delete', '/profitdata', {data: data});
}

export const Profitdatascript = (data) => {
  return makeRequest('post', '/profitscript', data)
}

/* salesman Apis */ 

export const getSalesmandata = () => {
  return makeRequest('get', '/salesmandata');
}

export const deleteSalesmandata = (data) => {
  return makeRequest('delete', '/salesmandata', {data: data});
}

export const Salesmandatascript = (data) => {
  return makeRequest('post', '/salesmancript', data)
}

/* Invoice Apis */

export const uploadpl = (data) => {
  return makeRequest('post', '/pl', data)
}

export const runscript2 = (data) => {
  return makeRequest('post', '/runscript', data)
}

/* Indian Invoice Processing */
export const uploadIndInvoice = (data) => {
  return makeRequest('post', '/upload_in_invoice', data);
}
export const runInIndInvoiceScript = (data) => {
  return makeRequest('post', '/run_in_invoice', data);
}
export const getIndInvoice = (group_id) => {
  return makeRequest('get', `/in_invoice/${group_id}`);
}
export const editIndInvoice = (data) => {
  return makeRequest('put', '/in_invoice', data);
}
export const postIndInvoice = (data) => {
  return makeRequest('post', '/in_invoice', data);
}
export const deleteIndInvoice = (data) => {
  return makeRequest('delete', '/in_invoice', {data: data});
}

/* Indian Invoice Items */
export const getIndInvoiceItems = (group_id) => {
  return makeRequest('get', `/in_invoice_items/${group_id}`);
}
export const postIndInvoiceItems = (data) => {
  return makeRequest('post', '/in_invoice_items', data);
}
export const postIndInvoiceItems2 = (data) => {
  return makeRequest('post', '/in_invoice_items2', data);
}
export const editIndInvoiceItems = (data) => {
  return makeRequest('put', '/in_invoice_items', data);
}
export const deleteIndInvoiceItems = (data) => {
  return makeRequest('delete', '/in_invoice_items', {data: data});
}

/* Indian Invoice Temporary */
export const getIndInvoiceTemp = (group_id) => {
  return makeRequest('get', `/in_invoice_temp/${group_id}`);
}
export const postIndInvoiceTemp = (data) => {
  return makeRequest('post', '/in_invoice_temp', data);
}
export const editIndInvoiceTemp = (data) => {
  return makeRequest('put', '/in_invoice_temp', data);
}
export const deleteIndInvoiceTemp = (data) => {
  return makeRequest('delete', '/in_invoice_temp', {data: data});
}

/* Bank Statement  */
export const uploadBankStmt = (data) => {
  return makeRequest('post', '/upload_bank_stmt', data);
}
export const runBankScript = (data) => {
  return makeRequest('post', '/run_bank_script', data);
}
export const getBankStmt = (group_id) => {
  return makeRequest('get', `/bank_stmt/${group_id}`);
}
export const editBankStmt = (data) => {
  return makeRequest('put', '/bank_stmt', data);
}
export const postBankStmt = (data) => {
  return makeRequest('post', '/bank_stmt', data);
}
export const deleteBankStmt = (data) => {
  return makeRequest('delete', '/bank_stmt', {data: data});
}
export const downloadBankStatement = (data) => {
  return makeRequest('post', '/bank_download', data);
}

/* Credit card Statement  */
export const uploadCreditStmt = (data) => {
  return makeRequest('post', '/upload_credit_stmt', data);
}
export const runCreditScript = (data) => {
  return makeRequest('post', '/run_credit_script', data);
}
export const getCreditStmt = (group_id) => {
  return makeRequest('get', `/credit_stmt/${group_id}`);
}
export const editCreditStmt = (data) => {
  return makeRequest('put', '/credit_stmt', data);
}
export const postCreditStmt = (data) => {
  return makeRequest('post', '/credit_stmt', data);
}
export const deleteCreditStmt = (data) => {
  return makeRequest('delete', '/credit_stmt', {data: data});
}
export const downloadCreditStatement = (data) => {
  return makeRequest('post', '/credit_download', data);
}

/* Temp Bank Statement */
export const getTempBank = (group_id) => {
  return makeRequest('get', `/temp_bank/${group_id}`);
}
export const editTempBank = (data) => {
  return makeRequest('put', '/temp_bank', data);
}
export const postTempBank = (data) => {
  return makeRequest('post', '/temp_bank', data);
}
export const deleteTempBank = (data) => {
  return makeRequest('delete', '/temp_bank', {data: data});
}

/* Temp Credit Statement */
export const getTempCredit = (group_id) => {
  return makeRequest('get', `/temp_credit/${group_id}`);
}
export const editTempCredit = (data) => {
  return makeRequest('put', '/temp_credit', data);
}
export const postTempCredit = (data) => {
  return makeRequest('post', '/temp_credit', data);
}
export const deleteTempCredit = (data) => {
  return makeRequest('delete', '/temp_credit', {data: data});
}

/* Tags for Bank & Credit Statement */
export const getTags = (group_id) => {
  return makeRequest('get', `/tags/${group_id}`);
}
export const editTags = (data) => {
  return makeRequest('put', '/tags', data);
}
export const postTags = (data) => {
  return makeRequest('post', '/tags', data);
}
export const deleteTags = (data) => {
  return makeRequest('delete', '/tags', {data: data});
}








